import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from 'gatsby'

const Abbreviations = () => (
    <Layout>
        <Seo title="Evolutionary astrology" />
        <h1>Common Abbreviations</h1>
        <p>The following abbreviations are commonly used throughout the <Link to="/blog">blog</Link>:</p>
        <ul>
            <li>NN: North Node of the Moon</li>
            <li>NNr: North Node ruler</li>
            <li>SN: South Node of the Moon</li>
            <li>SNr: South Node ruler</li>
            <li>PPP: Pluto Polarity Point</li>
            <li>H1, H2 etc: The first house, the second house</li>
            {/* <li>Virgo --&gt; 8H, Cancer --&gt; 3H etc: Virgo impacting the eighth house, Cancer impacting the third house</li> */}
        </ul>
        <p>If I work with the nodes of a planet, this will always be spelled out.</p>

        <Link to="/evolutionary-astrology">Back to Articles</Link>

    </Layout>
)

export default Abbreviations